import React from "react";
import Box from "@mui/material/Box";

const PlanBox = ({
  title,
  credits,
  name,
  description,
  image, // Replace with your image URL
  isMobile,
  activities,
}) => {
  return (
    <div style={{ ...styles.container, height: isMobile ? "auto" : "450px" }}>
      {/* Profile Section */}
      <div style={styles.profileSection}>
        <img src={image} alt="profile" style={styles.image} />
        <div>
          <h2 style={styles.title}>{title}</h2>
          <p style={styles.credits}>{credits} créditos</p>
          <p style={styles.name}>{name}</p>
        </div>
      </div>

      {/* Description */}
      <p style={styles.description}>{description}</p>

      {/* Actions Section */}
      <div style={styles.actionsSection}>
        {activities.map((activity, index) => (
          <div key={index} style={styles.actionItem}>
            <p style={styles.actionText}>
              {activity.name}{" "}
              <span style={styles.actionValue}> x{activity.count}</span>{" "}
              {activity.perClassCredits && (
                <p style={styles.smallText}>
                  {activity.perClassCredits} créditos clase
                </p>
              )}
              <span style={styles.actionValue}>Total: </span>
              <span style={styles.actionCredits}>
                {activity.totalCredits} créditos
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

// Styles
const styles = {
  container: {
    border: "1px solid #ccc",
    borderRadius: "30px",
    padding: "16px",
    width: "300px",
    // height: isMobile ? "auto" : "450px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
  },
  profileSection: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  image: {
    borderRadius: "50%",
    width: "60px",
    // height: "50px",
    marginRight: "16px",
  },
  title: {
    margin: "0",
    fontSize: "18px",
    color: "#0056b3",
    fontWeight: "bold",
  },
  credits: {
    margin: "0",
    fontSize: "16px",
    color: "#666",
  },
  name: {
    margin: "0",
    fontSize: "14px",
    color: "#666",
    fontStyle: "italic",
  },
  description: {
    fontSize: "14px",
    color: "#555",
    marginBottom: "16px",
  },
  actionsSection: {
    borderTop: "1px solid #ccc",
    paddingTop: "5px",
  },
  actionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: "-10px",
  },
  actionText: {
    fontSize: "14px",
    color: "#333",
  },
  actionDetails: {
    textAlign: "right",
  },
  actionValue: {
    fontSize: "14px",
    color: "#0056b3",
    fontWeight: "bold",
  },
  actionCredits: {
    fontSize: "14px",
    color: "#666",
  },
  smallText: {
    fontSize: "12px",
    color: "#888",
    marginTop: "5px",
  },
};

export default PlanBox;
