import React from "react";

import Box from "@mui/material/Box";
// import BusinessIcon from "@mui/icons-material/Business";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Typography from "@mui/material/Typography";
import UsersIcon from "@mui/icons-material/PeopleAlt";

import app1 from "../assets/app1.png";
import MainHome1 from "../assets/home1.jpg";
import Home2 from "../assets/home2.jpeg";
import Home3 from "../assets/home3.jpg";

const HomeMobile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          background: `url(${MainHome1}) center/cover no-repeat`,
          marginTop: "20px",
          boxShadow:
            "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
        }}
      >
        <Box
          className="t-home1"
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: "row",
            justifyContent: "center",
            minHeight: "400px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              width: "100%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "38px",
              },
              fontWeight: "bold",
              color: "#527CA7",
              marginTop: "9%",
              textAlign: "center",
            }}
          >
            La cultura<br></br> a tu medida
          </Typography>
          <Typography
            sx={{
              width: "80%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "22px",
              },
              fontWeight: "600",
              color: "#527CA7",
              textAlign: "center",
              marginTop: "20px!important",
              margin: "0 auto",
            }}
          >
            Actividades y cultura en un único sitio.
          </Typography>
          <Typography
            sx={{
              width: "80%",
              height: "auto",
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "18px",
              },
              fontWeight: "540",
              color: "#262629",
              textAlign: "center",
              marginTop: "20px!important",
              margin: "0 auto",
            }}
          >
            Con un simple click, Cultus te permite reservar teatros, cines,
            clases de cerámica, pintura y mucho más.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "none",
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            color: "#262629",
          }}
        >
          ¿Qué es Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "70%",
            textAlign: "center",
          }}
        >
          Cultus es una programa de suscripción mensual en eventos y clases de
          cultura y ocio. Clases de pintura, música, baile, así como cines,
          teatros y otros espectáculos son algunas de las actividades dentro de
          la oferta de Cultus.
          <br />
          Dentro del marketplace de Cultus podréis encontrar clases de pintura,
          cerámica, idiomas, baile, etc. También eventos y actividades como
          museos, cines, teatros y mucho más.
        </Typography>
      </Box>
      {/* Columns for Partners/Empresas/Users */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          mt: "15%",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#527CA7",
            borderRadius: "30px",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HandshakeIcon
              sx={{
                width: "90px",
                height: "90px",
                color: "#e2e3e6",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#e2e3e6",
                textAlign: "center",
              }}
            >
              Partners
            </Typography>
          </Box>
          {/* Detail */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#e2e3e6",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Conseguirás nuevos clientes y más visibilidad para tu negocio.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "80%",
            height: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            borderRadius: "30px",
            // marginTop: "8%",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <BusinessIcon
              sx={{
                width: "90px",
                height: "90px",
                color: "#262629",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#262629",
                textAlign: "center",
              }}
            >
              Empresas
            </Typography> */}
          </Box>
          {/* Detail */}
          {/* <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#262629",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Ofrece a tus empleados una gran variedad de actividades
              culturales, consiguiendo que disfruten de este beneficio y
              reteniendo así el talento.
            </Typography>
          </Box> */}
        </Box>

        <Box
          sx={{
            width: "80%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#527CA7",
            borderRadius: "30px",
            marginTop: "8%",
          }}
        >
          {/* Title of section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: "7%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UsersIcon
              sx={{
                width: "80px",
                height: "80px",
                color: "#e2e3e6",
                marginRight: "5%",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontFamily: "'Quicksand', sans-serif",
                fontWeight: "bold",
                color: "#e2e3e6",
                textAlign: "center",
              }}
            >
              Usuarios
            </Typography>
          </Box>
          {/* Detail */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "1%",
                fontFamily: "'Quicksand', sans-serif",
                color: "#e2e3e6",
                width: "70%",
                marginLeft: "15%",
                marginRight: "15%",
                textAlign: "center",
              }}
            >
              Acceso a un marketplace con un gran número de actividades a un
              precio competitivo.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: "80px",
          alignItems: "center",
          marginTop: "5%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            fontSize: "1.825rem",
            textAlign: "center",
            color: "#262629",
          }}
        >
          ¿Cómo funciona Cultus?
        </Typography>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            marginTop: "1%",
            fontFamily: "'Quicksand', sans-serif",
            color: "#231b23",
            width: "60%",
            textAlign: "center",
          }}
        >
          Reserva tus actividades desde una única aplicación. Adquiere tú
          suscripción mensual y recibe un saldo para gastar en las actividades
          que tú quieras. Tú gestionas a dónde ir y cuándo ir, de una forma
          sencilla y cómoda.<br></br> Aquí tienes más información sobre los
          <strong> planes</strong>.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20%",
          gap: "12%",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
              textAlign: "center",
            }}
          >
            La mayor red de clases de cultura y ocio
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
              textAlign: "center",
            }}
          >
            Únete a la mayor red de centros de clases de pintura, cerámica,
            baile, idiomas y mucho más. Congregados todos en un único
            marketplace. Aprovecha y disfruta de tu tiempo.
          </Typography>
          {/* <Typography
            sx={{
              marginTop: "30px",
              fontFamily: "'Quicksand', sans-serif",
              color: "#537ca8",
              fontWeight: "400",
              "&:hover": {
                fontWeight: "900",
              },
              width: "100%",
              textAlign: "center",
            }}
          >
            Más detalle de cómo funciona para empresas
          </Typography> */}
        </Box>
        <Box
          component="img"
          src={Home2}
          sx={{
            width: "70%",
            height: "auto",
            marginTop: "4%",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3%",
          gap: "12%",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
              textAlign: "center",
            }}
          >
            Todo en una sola App
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
              textAlign: "center",
            }}
          >
            Descargate nuestra app, elige el plan que más se ajuste a tu estilo
            de vida y disfruta de todo esto con solo un click.
          </Typography>
        </Box>
        <Box
          component="img"
          src={app1}
          sx={{
            width: "52%",
            height: "auto",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12%",
          gap: "10%",
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              color: "#262629",
              textAlign: "center",
            }}
          >
            Diversifica y disfruta de la cultura
          </Typography>
          <Typography
            sx={{
              marginTop: "3%",
              fontFamily: "'Quicksand', sans-serif",
              color: "#262629",
              textAlign: "center",
            }}
          >
            Con una sola suscripción podrás ir a todas las clases que siempre
            has querido. Ahora puedes compaginar la música con las clases de
            inglés en un mismo plan.
          </Typography>
        </Box>
        <Box
          component="img"
          src={Home3}
          sx={{
            width: "68%",
            height: "auto",
            marginTop: "8%",
            boxShadow:
              "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          width: "30%",
          height: "100px",
          backgroundColor: "white",
        }}
      ></Box>
    </Box>
  );
};

export default HomeMobile;
