import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlanBox from "../components/PlanBox";

const Plans = ({ isMobile }) => {
  const navigate = useNavigate();

  const plans = [
    { title: "Curioso", credits: "20 créditos", price: "34,99 €/mes" },
    { title: "Interesado", credits: "35 créditos", price: "49,99 €/mes" },
    { title: "Inquieto", credits: "50 créditos", price: "74,99 €/mes" },
    { title: "Entusiasta", credits: "75 créditos", price: "99,99 €/mes" },
    { title: "Apasionado", credits: "110 créditos", price: "149,99 €/mes" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: { sm: "-1px", xs: "20px" },
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          textAlign: "center",
          width: { sm: "50%", xs: "90%" },
          marginTop: { sm: "7%", xs: "20px" },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontSize: { sm: "44px", xs: "34px" },
            fontWeight: "bold",
            color: "#527CA7",
            marginTop: { sm: "5%", xs: "10px" },
          }}
        >
          Escoge el plan que más se ajuste con tu estilo de vida
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            marginTop: "20px",
            color: "#262629",
            fontSize: { sm: "18px", xs: "16px" },
          }}
        >
          Elige tú plan y empieza a disfrutar de las actividades que más te
          gusten. Ahora podrás compaginar las clases de pintura, cerámica,
          idiomas y mucho más.
        </Typography>
      </Box>

      {/* Plans Section */}
      <Box
        sx={{
          width: { sm: "80%", xs: "90%" },
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
          marginTop: "5%",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        {plans.map((plan, index) => (
          <PlanBox
            key={index}
            title={plan.title}
            credits={plan.credits}
            price={plan.price}
          />
        ))}
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          marginTop: "5%",
        }}
      />
    </Box>
  );
};

export default Plans;
