import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UseOfCase from "../components/UseOfCase";

import example1 from "../assets/example1.jpg";
import example2 from "../assets/example2.jpg";
import example3 from "../assets/example3.jpg";

const HowToUse = ({ isMobile }) => {
  const navigate = useNavigate();

  const plans = [
    { title: "Curioso", credits: "20 créditos", price: "34,99 €/mes" },
    { title: "Interesado", credits: "35 créditos", price: "49,99 €/mes" },
    { title: "Inquieto", credits: "50 créditos", price: "74,99 €/mes" },
    { title: "Entusiasta", credits: "75 créditos", price: "99,99 €/mes" },
    { title: "Apasionado", credits: "110 créditos", price: "149,99 €/mes" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: { sm: "-1px", xs: "20px" },
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          textAlign: "center",
          width: { sm: "50%", xs: "90%" },
          marginTop: { sm: "7%", xs: "20px" },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontSize: { sm: "44px", xs: "34px" },
            fontWeight: "bold",
            color: "#527CA7",
            marginTop: { sm: "5%", xs: "10px" },
          }}
        >
          Escoge el plan que más se ajuste con tu estilo de vida
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            marginTop: "20px",
            color: "#262629",
            fontSize: { sm: "18px", xs: "16px" },
          }}
        >
          Elige tú plan y empieza a disfrutar de las actividades que más te
          gusten. Ahora podrás compaginar las clases de pintura, cerámica,
          idiomas y mucho más.
        </Typography>
      </Box>

      {/* Plans Section */}
      <Box
        sx={{
          width: { sm: "80%", xs: "90%" },
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
          marginTop: "5%",
          flexDirection: { sm: "row", xs: "column" },
        }}
      >
        {/* {plans.map((plan, index) => (
          <PlanBox
            key={index}
            title={plan.title}
            credits={plan.credits}
            price={plan.price}
          />
        ))} */}
        <UseOfCase
          title="Plan Curioso"
          credits={20}
          name="Antonio"
          description="Antonio suele hacer una sesión de inglés cada dos semanas. Y cuando le sobran créditos los aprovecha el mes siguiente para ir al cine."
          image={example1}
          activities={[
            {
              name: "Sesión de inglés",
              count: 2,
              totalCredits: 16,
              perClassCredits: 8,
            },
            {
              name: "Entrada cine",
              count: 1,
              totalCredits: 4,
              perClassCredits: 4,
            },
          ]}
          isMobile={isMobile}
        />
        <UseOfCase
          title="Plan Inquieto"
          credits={50}
          name="Marta"
          description="A Marta le encantan los talleres de cerámica y va una vez por semana. 
También le gusta el teatro y suele ir una vez al mes.
"
          image={example2}
          activities={[
            {
              name: "Taller cerámica",
              count: 4,
              totalCredits: 44,
              perClassCredits: 11,
            },
            {
              name: "Entrada teatro",
              count: 1,
              totalCredits: 6,
              perClassCredits: 6,
            },
          ]}
          isMobile={isMobile}
        />
        <UseOfCase
          title="Plan Entusiasta"
          credits={50}
          name="Jose Carlos"
          description="Jose Carlos toca la guitarra una vez por semana. Y también va a clases de chino para su próximo viaje este verano. Con lo que sobra ha ido al teatro.
"
          image={example3}
          activities={[
            {
              name: "Clases de guitarra",
              count: 4,
              totalCredits: 28,
              perClassCredits: 7,
            },
            {
              name: "Clases de chino",
              count: 4,
              totalCredits: 40,
              perClassCredits: 10,
            },
            {
              name: "Entrada teatro",
              count: 1,
              totalCredits: 7,
              perClassCredits: 7,
            },
          ]}
          isMobile={isMobile}
        />
      </Box>

      {/* Footer Section */}
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          marginTop: "5%",
        }}
      />
    </Box>
  );
};

export default HowToUse;
