import React from "react";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";

const PlanBox = ({ title, credits, price }) => {
  const styles = {
    container: {
      width: "180px",
      padding: "20px",
      borderRadius: "30px",
      backgroundColor: "#e5eff7",
      textAlign: "center",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
      fontFamily: "Rubik, sans-serif",
    },
    title: {
      color: "#336699",
      fontSize: "1.3em",
      fontWeight: "bold",
      margin: "10px 0",
    },
    credits: {
      fontSize: "1.2em",
      //   fontWeight: "bold",
      margin: "10px 0",
      color: "#333",
    },
    priceRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      margin: "10px 0",
    },
    icon: {
      fontSize: "1.4em",
      color: "#336699",
    },
    price: {
      fontSize: "1.2em",
      fontWeight: "bold",
      color: "#333",
    },
    button: {
      marginTop: "20px",
      padding: "10px 20px",
      backgroundColor: "#336699",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "1em",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>{title}</div>
      <div style={styles.priceRow}>
        <MonetizationOnRoundedIcon style={styles.icon} />
        <div style={styles.credits}>{credits}</div>
      </div>
      <div style={styles.price}>{price}</div>
      <button style={styles.button}>Obtener Plan</button>
    </div>
  );
};

export default PlanBox;
