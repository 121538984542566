import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CultLogo from "../assets/cult_logo.svg";
import handleClick from "../utils/ButtonClickFunctions";

const Asociate = ({ isMobile }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    country: "España",
    full_name: "",
    company_name: "",
    company_mail: "",
    phone_number: "",
  });
  const [captchaValue, setCaptchaValue] = useState("");
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleLogoClick = () => {
    handleClick("/", navigate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      console.error("Please complete the CAPTCHA");
      return;
    }

    emailjs
      .send(
        process.env.REACT_APP_email_js_service_ud,
        process.env.REACT_APP_email_js_template,
        formData,
        process.env.REACT_APP_email_js_user_id
      )
      .then(
        (response) => {
          setShowSuccessAlert(true);
          setFormData({
            country: "España",
            full_name: "",
            company_name: "",
            company_mail: "",
            phone_number: "",
          });
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  const isFormValid = Object.values(formData).every(
    (value) => value.trim() !== ""
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "flex-start",
          height: "100vh",
        }}
      >
        <Box
          className="box-image"
          sx={{
            position: "static",
            width: "30%",
            height: "auto",
            left: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: { sm: "flex-start", xs: "center" },
            paddingLeft: { sm: "100px", xs: "0px" },
            marginTop: "50px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleLogoClick}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontSize: {
                xs: "22px",
                sm: "32px",
              },
              fontWeight: "bold",
              textAlign: "center",
              color: "#527CA7",
              marginLeft: "6px",
            }}
          >
            cultus
          </Typography>
          <img
            src={CultLogo}
            alt="Cult Logo"
            style={{
              width: "37.9px",
              height: "auto",
              marginLeft: "10px",
              fill: "#527CA7",
              justifyContent: "center",
            }}
          />
        </Box>
        <Typography
          variant="h1"
          sx={{
            width: "100%",
            height: "auto",
            fontFamily: "'Quicksand', sans-serif",
            fontSize: {
              xs: "20px",
              sm: "44px",
            },
            fontWeight: "bold",
            color: "#231b23",
            marginTop: { sm: "25%", xs: "15%" },
            marginLeft: { sm: "100px", xs: "0px" },
            textAlign: { sm: "left", xs: "center" },
          }}
        >
          Impulsa tu negocio <br></br>con Cultus
        </Typography>
        <Typography
          variant="h6"
          sx={{
            width: "100%",
            height: "auto",
            fontFamily: "'Quicksand', sans-serif",
            fontSize: {
              xs: "20px",
              sm: "20px",
            },
            fontWeight: "500",
            color: "#231b23",
            marginTop: { sm: "0px", xs: "20px" },
            marginLeft: { sm: "100px", xs: "0px" },
            textAlign: { sm: "left", xs: "center" },
          }}
        >
          Inscríbete de forma gratuita y entra en <br></br> la gran familia de
          Cultus.
        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: { sm: "0px", xs: "-300px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            fontFamily: "'Quicksand', sans-serif",
          }}
        >
          <TextField
            disabled
            required
            id="country"
            label="País"
            defaultValue="España"
            placeholder="España"
            variant="filled"
            sx={{ width: "70%" }}
            onChange={handleInputChange}
          />
          <TextField
            required
            id="full_name"
            label="Nombre"
            placeholder="John Doe"
            sx={{ width: "70%", marginTop: "7%" }}
            onChange={handleInputChange}
          />
          <TextField
            required
            id="company_name"
            label="Nombre Negocio"
            placeholder="Cultus S.L."
            sx={{ width: "70%", marginTop: "7%" }}
            onChange={handleInputChange}
          />
          <TextField
            required
            id="company_mail"
            label="Email de contacto"
            placeholder="john.doe@cultus.com"
            sx={{ width: "70%", marginTop: "7%" }}
            onChange={handleInputChange}
          />
          <TextField
            required
            id="phone_number"
            label="Número de contacto"
            placeholder="666 666 666"
            sx={{ width: "70%", marginTop: "7%" }}
            onChange={handleInputChange}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: { sm: "left", xs: "center" },
              width: { sm: "70%", xs: "100%" },
              marginTop: "7%",
            }}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_recaptcha_site_key}
              onChange={(value) => setCaptchaValue(value)}
            />
          </Box>
          {isFormValid && captchaValue ? (
            <Box
              sx={{
                width: "70%",
                marginTop: "7%",
                height: "50px",
                backgroundColor: "#527CA7",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#375D7B",
                  fontWeight: "bold",
                },
              }}
              onClick={handleSubmit}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "'Quicksand', sans-serif",
                }}
              >
                Enviar y que me contacten
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                width: "70%",
                marginTop: "7%",
                height: "50px",
                backgroundColor: "#CCCCCC",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "not-allowed",
              }}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "'Quicksand', sans-serif",
                }}
              >
                Enviar y que me contacten
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {showSuccessAlert && (
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          onClose={() => {
            setShowSuccessAlert(false);
            handleClick("/partners", navigate);
          }}
          sx={{
            position: "fixed",
            top: 20,
            left: "50%",
            transform: "translateX(-50%)",
            width: { xs: "80%" },
          }}
        >
          ¡Correo enviado correctamente!
        </Alert>
      )}
    </Box>
  );
};

export default Asociate;
