const handleClick = (path, navigate) => {
  navigate(path);
  // window.scrollTo(0, 0);
  const scrollTarget = document.documentElement || document.body;
  if (scrollTarget) {
    scrollTarget.scrollTop = 0;
  }
};

export default handleClick;
