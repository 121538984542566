import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Asociate from "./pages/Asociate";
import Empleados from "./pages/Empleados";
import Empresas from "./pages/Empresas";
import Landing from "./pages/Landing";
import MobileLanding from "./pages/MobileLanding";
import Home from "./pages/Home";
import HowToUse from "./pages/HowToUse";
import MobileHome from "./pages/MobileHome";
import Partners from "./pages/Partners";
import Plans from "./pages/Plans";
import LandingLayout from "./Layouts/LandingLayout";
import Layout from "./Layouts/Layout";
import Wip from "./pages/Wip";

import "./App.css";

const theme = createTheme();

const App = () => {
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") && "(max-width: 800px)"
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/landing"
            element={
              <LandingLayout isMobile={isMobile}>
                {isMobile ? <MobileLanding /> : <Landing />}
              </LandingLayout>
            }
          />
          <Route
            path="/"
            element={
              <Layout isMobile={isMobile}>
                {isMobile ? <MobileHome /> : <Home />}
              </Layout>
            }
          />
          <Route path="/asociate" element={<Asociate isMobile={isMobile} />} />
          <Route
            path="/empleados"
            element={
              <Layout isMobile={isMobile}>
                <Empleados isMobile={isMobile} />
              </Layout>
            }
          />
          <Route
            path="/empresas"
            element={
              <Layout isMobile={isMobile}>
                <Empresas isMobile={isMobile} />
              </Layout>
            }
          />
          <Route
            path="/partners"
            element={
              <Layout isMobile={isMobile}>
                <Partners isMobile={isMobile} />
              </Layout>
            }
          />
          <Route
            path="/plans"
            element={
              <Layout isMobile={isMobile}>
                <Plans isMobile={isMobile} />
              </Layout>
            }
          />
          <Route
            path="/how-to-use"
            element={
              <Layout isMobile={isMobile}>
                <HowToUse isMobile={isMobile} />
              </Layout>
            }
          />
          <Route path="/wip" element={<Wip isMobile={isMobile} />} />
          <Route path="*" element={<Wip isMobile={isMobile} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
