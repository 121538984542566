import React from "react";
import { useNavigate } from "react-router-dom";

import AutoModeIcon from "@mui/icons-material/AutoMode";
import Box from "@mui/material/Box";
import EuroIcon from "@mui/icons-material/Euro";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";

import app2 from "../assets/cultus_app_login.jpg";
import handleClick from "../utils/ButtonClickFunctions";
import Partner1 from "../assets/partner1.jpg";

const Partners = ({ isMobile }) => {
  const navigate = useNavigate();
  const boxData = [
    {
      title: "Mayor visibilidad",
      text: "Tu empresa aparecerá en nuestra app, lo que te ayudará a llegar a un público más amplio.",
      icon: (
        <VisibilityIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Más tráfico",
      text: "Te enviaremos clientes potenciales que estén interesados en tus productos o servicios",
      icon: (
        <AutoModeIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Aumento de ingresos",
      text: "Al tener más visitas y clientes potenciales, tus ventas e ingresos aumentarán considerablemente.",
      icon: (
        <EuroIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
    {
      title: "Crecimiento de tu negocio",
      text: "Nuestro programa te ayudará a hacer crecer tu negocio y alcanzar tus objetivos.",
      icon: (
        <TrendingUpIcon
          sx={{
            width: { sm: "8%", xs: "14%" },
            height: "auto",
            color: "#527CA7",
          }}
        />
      ),
    },
  ];
  const boxData1 = [
    {
      icon: <LooksOneIcon />,
      text: "Crea tu cuenta de forma gratuita.",
    },
    {
      icon: <LooksTwoIcon />,
      text: "Completa tu perfil con información sobre tu empresa, productos o servicios.",
    },
    {
      icon: <Looks3Icon />,
      text: "Comienza a recibir clientes potenciales y aumentar tus ingresos.",
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "row",
          marginTop: { sm: "80px", xs: "20px" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box
            sx={{
              width: {
                sm: "60%",
                xs: "90%",
              },
              height: "auto",
              mt: "4%",
              display: "flex",
              flexDirection: "column",
              justifyContent: { xs: "center" },
              alignItems: { xs: "center" },
            }}
          >
            <Typography
              variant="h1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  sm: "44px",
                  xs: "34px",
                },
                fontWeight: "bold",
                color: "#527CA7",
                marginTop: { sm: "5%" },
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Impulsa tu negocio al siguiente nivel asociándote con Cultus.
            </Typography>
            <Typography
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                },
                fontWeight: "600",
                color: "#527CA7",
                marginTop: "20px",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Descubre una red de oportunidades para aumentar tus visitas,
              ingresos y visibilidad.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                width: {
                  sm: "50%",
                  xs: "90%",
                },
                height: "auto",
                fontFamily: "'Quicksand', sans-serif",
                marginTop: "20px",
                color: "#262629",
                textAlign: {
                  xs: "center",
                  sm: "left",
                },
              }}
            >
              Cultus es un programa de suscripción mensual en eventos y clases
              que promueve la cultura. Únete a nuestra red de partners de forma
              totalmente gratuita y aprovecha todas las ventajas.
            </Typography>
            <Box
              sx={{
                width: { sm: "50%", xs: "90%" },
                display: "flex",
                marginTop: "50px",
                justifyContent: { xs: "center", sm: "flex-start" },
                alignItems: { xs: "center" },
              }}
            >
              <Box
                sx={{
                  width: "200px",
                  height: "50px",
                  backgroundColor: "#527CA7",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#375D7B",
                    fontWeight: "bold",
                  },
                }}
                onClick={() => handleClick("/asociate", navigate)}
              >
                <Typography
                  variant="button"
                  sx={{
                    color: "#FFFFFF",
                    textTransform: "none",
                    fontSize: "16px",
                    fontFamily: "'Quicksand', sans-serif",
                  }}
                >
                  Asóciate con Cultus
                </Typography>
              </Box>
            </Box>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "25%",
                marginTop: "5%",
              }}
            >
              <Box
                component="img"
                src={Partner1}
                sx={{
                  width: "100%",
                  height: "auto",
                  boxShadow:
                    "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: "5%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: { sm: "100%", xs: "80%" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Quicksand', sans-serif",
              fontWeight: "600",
              fontSize: "1.825rem",
              textAlign: "center",
              color: "#262629",
            }}
          >
            Beneficios para tu negocio
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr", xs: "1fr" },
            gap: { sm: "16px", xs: "20px" },
            rowGap: "30px",
            width: { sm: "70%", xs: "70%" },
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "3%",
          }}
        >
          {boxData.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "30px",
                width: "100%",
                height: { sm: "250px", xs: "280px" },
                boxShadow:
                  "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
              }}
            >
              {data.icon}

              <Typography
                variant="h5"
                textAlign="center"
                sx={{ width: { sm: "70%", xs: "90%" }, mt: "5%" }}
              >
                {data.title}
              </Typography>

              <Typography
                variant="body1"
                textAlign="center"
                sx={{ width: { sm: "50%", xs: "70%" }, mt: "2%" }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: { sm: "100%", xs: "100%" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: "600",
            fontSize: "1.825rem",
            textAlign: "center",
            color: "#262629",
            mt: "5%",
          }}
        >
          ¿Cómo funciona?
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { sm: "row", xs: "column" },
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          mt: "-5%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: { sm: "50%", xs: "100%" },
            ml: { sm: "-170px" },
            mt: { xs: "8%" },
          }}
        >
          {boxData1.map((data, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "70%",
                margin: "8px",
              }}
            >
              <Box sx={{ marginRight: "16px" }}>{data.icon}</Box>

              <Typography
                variant="body1"
                textAlign="left"
                sx={{ width: { sm: "70%", xs: "90%" } }}
              >
                {data.text}
              </Typography>
            </Box>
          ))}
          <Box
            sx={{
              width: { sm: "50%", xs: "100%" },
              display: "flex",
              marginTop: "50px",
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "50px",
                backgroundColor: "#527CA7",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#375D7B",
                  fontWeight: "bold",
                },
              }}
              onClick={() => handleClick("/asociate", navigate)}
            >
              <Typography
                variant="button"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "'Quicksand', sans-serif",
                }}
              >
                Asóciate con Cultus
              </Typography>
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <Box
            component="img"
            src={app2}
            sx={{
              width: "18%",
              height: "auto",
              mt: "8%",
              ml: "8px%",
              boxShadow:
                "rgba(152, 152, 166, 0.2) 0px 5px 5px -3px, rgba(152, 152, 166, 0.14) 0px 8px 10px 1px, rgba(152, 152, 166, 0.12) 0px 3px 14px 2px",
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundColor: "white",
          mt: "5%",
        }}
      ></Box>
    </Box>
  );
};

export default Partners;
